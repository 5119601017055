/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EmployeeDetailDto } from '../model/employeeDetailDto';
import { EmployeeOverviewDto } from '../model/employeeOverviewDto';
import { EmployeeSearchDto } from '../model/employeeSearchDto';
import { EmployeeSearchDtoSearchResponse } from '../model/employeeSearchDtoSearchResponse';
import { EmployeeSearchRequestDto } from '../model/employeeSearchRequestDto';
import { EmployeeStatusDto } from '../model/employeeStatusDto';
import { LastKnownGroupForCopyDayBookingRequestDto } from '../model/lastKnownGroupForCopyDayBookingRequestDto';
import { SettingsDto } from '../model/settingsDto';
import { TimeBookingEmployeeGroupRequestDto } from '../model/timeBookingEmployeeGroupRequestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EmployeeService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public favorite(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public favorite(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public favorite(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public favorite(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling favorite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/employee/${encodeURIComponent(String(id))}/favorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(observe?: 'body', reportProgress?: boolean): Promise<EmployeeOverviewDto>;
    public get(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<EmployeeOverviewDto>>;
    public get(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<EmployeeOverviewDto>>;
    public get(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/employee`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param externalId 
     * @param tenant 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByExternalId(externalId: string, tenant: number, observe?: 'body', reportProgress?: boolean): Promise<EmployeeOverviewDto>;
    public getByExternalId(externalId: string, tenant: number, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<EmployeeOverviewDto>>;
    public getByExternalId(externalId: string, tenant: number, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<EmployeeOverviewDto>>;
    public getByExternalId(externalId: string, tenant: number, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (externalId === null || externalId === undefined) {
            throw new Error('Required parameter externalId was null or undefined when calling getByExternalId.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getByExternalId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/employee/${encodeURIComponent(String(tenant))}/${encodeURIComponent(String(externalId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: string, observe?: 'body', reportProgress?: boolean): Promise<EmployeeOverviewDto>;
    public getById(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<EmployeeOverviewDto>>;
    public getById(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<EmployeeOverviewDto>>;
    public getById(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/employee/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetail(id: string, observe?: 'body', reportProgress?: boolean): Promise<EmployeeDetailDto>;
    public getDetail(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<EmployeeDetailDto>>;
    public getDetail(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<EmployeeDetailDto>>;
    public getDetail(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/employee/${encodeURIComponent(String(id))}/detail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param tenant 
     * @param externalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetailByExternal(tenant: number, externalId: string, observe?: 'body', reportProgress?: boolean): Promise<EmployeeDetailDto>;
    public getDetailByExternal(tenant: number, externalId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<EmployeeDetailDto>>;
    public getDetailByExternal(tenant: number, externalId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<EmployeeDetailDto>>;
    public getDetailByExternal(tenant: number, externalId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getDetailByExternal.');
        }
        if (externalId === null || externalId === undefined) {
            throw new Error('Required parameter externalId was null or undefined when calling getDetailByExternal.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/employee/${encodeURIComponent(String(tenant))}/${encodeURIComponent(String(externalId))}/detail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param tenant 
     * @param externalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileImageLink(tenant: number, externalId: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public getProfileImageLink(tenant: number, externalId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public getProfileImageLink(tenant: number, externalId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public getProfileImageLink(tenant: number, externalId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getProfileImageLink.');
        }
        if (externalId === null || externalId === undefined) {
            throw new Error('Required parameter externalId was null or undefined when calling getProfileImageLink.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/employee/${encodeURIComponent(String(tenant))}/${encodeURIComponent(String(externalId))}/profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lastKnownGroupForBooking(body?: TimeBookingEmployeeGroupRequestDto, observe?: 'body', reportProgress?: boolean): Promise<Array<EmployeeSearchDto>>;
    public lastKnownGroupForBooking(body?: TimeBookingEmployeeGroupRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<EmployeeSearchDto>>>;
    public lastKnownGroupForBooking(body?: TimeBookingEmployeeGroupRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<EmployeeSearchDto>>>;
    public lastKnownGroupForBooking(body?: TimeBookingEmployeeGroupRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/employee/groupForBooking`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lastKnownGroupForCopyDayBooking(body?: LastKnownGroupForCopyDayBookingRequestDto, observe?: 'body', reportProgress?: boolean): Promise<Array<EmployeeSearchDto>>;
    public lastKnownGroupForCopyDayBooking(body?: LastKnownGroupForCopyDayBookingRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<EmployeeSearchDto>>>;
    public lastKnownGroupForCopyDayBooking(body?: LastKnownGroupForCopyDayBookingRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<EmployeeSearchDto>>>;
    public lastKnownGroupForCopyDayBooking(body?: LastKnownGroupForCopyDayBookingRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/employee/groupForBookingForCopyDay`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(body?: EmployeeSearchRequestDto, observe?: 'body', reportProgress?: boolean): Promise<EmployeeSearchDtoSearchResponse>;
    public search(body?: EmployeeSearchRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<EmployeeSearchDtoSearchResponse>>;
    public search(body?: EmployeeSearchRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<EmployeeSearchDtoSearchResponse>>;
    public search(body?: EmployeeSearchRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/employee/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unfavorite(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public unfavorite(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public unfavorite(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public unfavorite(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling unfavorite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/employee/${encodeURIComponent(String(id))}/unfavorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatus(body?: EmployeeStatusDto, observe?: 'body', reportProgress?: boolean): Promise<EmployeeDetailDto>;
    public updateStatus(body?: EmployeeStatusDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<EmployeeDetailDto>>;
    public updateStatus(body?: EmployeeStatusDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<EmployeeDetailDto>>;
    public updateStatus(body?: EmployeeStatusDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/employee/update-status`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param employeeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserSettings(employeeId: string, body?: SettingsDto, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public updateUserSettings(employeeId: string, body?: SettingsDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public updateUserSettings(employeeId: string, body?: SettingsDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public updateUserSettings(employeeId: string, body?: SettingsDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling updateUserSettings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.put(`${this.basePath}/api/employee/${encodeURIComponent(String(employeeId))}/settings`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
