


export type FormSignatureNecessityType = 'Mandatory' | 'Optional';

export const FormSignatureNecessityType = {
    Mandatory: 'Mandatory' as FormSignatureNecessityType,
    Optional: 'Optional' as FormSignatureNecessityType
};

