


export type MyBusinessEntityType = 'Employee' | 'Project' | 'GroupChannel' | 'News' | 'Info' | 'Customer' | 'Supplier' | 'Private' | 'AI' | 'Emergency';

export const MyBusinessEntityType = {
    Employee: 'Employee' as MyBusinessEntityType,
    Project: 'Project' as MyBusinessEntityType,
    GroupChannel: 'GroupChannel' as MyBusinessEntityType,
    News: 'News' as MyBusinessEntityType,
    Info: 'Info' as MyBusinessEntityType,
    Customer: 'Customer' as MyBusinessEntityType,
    Supplier: 'Supplier' as MyBusinessEntityType,
    Private: 'Private' as MyBusinessEntityType,
    AI: 'AI' as MyBusinessEntityType,
    Emergency: 'Emergency' as MyBusinessEntityType
};

