/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ContactDto } from '../model/contactDto';
import { ProjectDetailDto } from '../model/projectDetailDto';
import { ProjectOverviewDto } from '../model/projectOverviewDto';
import { ProjectSearchDtoSearchResponse } from '../model/projectSearchDtoSearchResponse';
import { ProjectSearchRequestDto } from '../model/projectSearchRequestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProjectService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public favorite(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public favorite(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public favorite(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public favorite(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling favorite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/project/${encodeURIComponent(String(id))}/favorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAlarmlistById(id: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public getAlarmlistById(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public getAlarmlistById(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public getAlarmlistById(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAlarmlistById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/project/${encodeURIComponent(String(id))}/alarmlist`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param externalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByExternalId(externalId: string, observe?: 'body', reportProgress?: boolean): Promise<ProjectOverviewDto>;
    public getByExternalId(externalId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ProjectOverviewDto>>;
    public getByExternalId(externalId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ProjectOverviewDto>>;
    public getByExternalId(externalId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (externalId === null || externalId === undefined) {
            throw new Error('Required parameter externalId was null or undefined when calling getByExternalId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/project/externalId/${encodeURIComponent(String(externalId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: string, observe?: 'body', reportProgress?: boolean): Promise<ProjectDetailDto>;
    public getById(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ProjectDetailDto>>;
    public getById(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ProjectDetailDto>>;
    public getById(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/project/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContacts(projectId: string, observe?: 'body', reportProgress?: boolean): Promise<Array<ContactDto>>;
    public getContacts(projectId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<ContactDto>>>;
    public getContacts(projectId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<ContactDto>>>;
    public getContacts(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getContacts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/project/${encodeURIComponent(String(projectId))}/contacts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getForCustomer(customerId: string, observe?: 'body', reportProgress?: boolean): Promise<Array<ProjectOverviewDto>>;
    public getForCustomer(customerId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<ProjectOverviewDto>>>;
    public getForCustomer(customerId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<ProjectOverviewDto>>>;
    public getForCustomer(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getForCustomer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/project/customer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(body?: ProjectSearchRequestDto, observe?: 'body', reportProgress?: boolean): Promise<ProjectSearchDtoSearchResponse>;
    public search(body?: ProjectSearchRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ProjectSearchDtoSearchResponse>>;
    public search(body?: ProjectSearchRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ProjectSearchDtoSearchResponse>>;
    public search(body?: ProjectSearchRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/project/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unfavorite(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public unfavorite(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public unfavorite(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public unfavorite(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling unfavorite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/project/${encodeURIComponent(String(id))}/unfavorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
