


export type WorkSpaceSearchRequestType = 'Direct' | 'Group' | 'News' | 'Info' | 'Project' | 'Customer' | 'Supplier' | 'Private' | 'AI' | 'Emergency';

export const WorkSpaceSearchRequestType = {
    Direct: 'Direct' as WorkSpaceSearchRequestType,
    Group: 'Group' as WorkSpaceSearchRequestType,
    News: 'News' as WorkSpaceSearchRequestType,
    Info: 'Info' as WorkSpaceSearchRequestType,
    Project: 'Project' as WorkSpaceSearchRequestType,
    Customer: 'Customer' as WorkSpaceSearchRequestType,
    Supplier: 'Supplier' as WorkSpaceSearchRequestType,
    Private: 'Private' as WorkSpaceSearchRequestType,
    AI: 'AI' as WorkSpaceSearchRequestType,
    Emergency: 'Emergency' as WorkSpaceSearchRequestType
};

