


export type ProjectContactType = 'Customer' | 'Billing' | 'Architect' | 'Location';

export const ProjectContactType = {
    Customer: 'Customer' as ProjectContactType,
    Billing: 'Billing' as ProjectContactType,
    Architect: 'Architect' as ProjectContactType,
    Location: 'Location' as ProjectContactType
};

