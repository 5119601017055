


export type RegieWorkTimeBookingType = 'FlatRate' | 'RegieWithBillingType' | 'WarrantyWithBillingType';

export const RegieWorkTimeBookingType = {
    FlatRate: 'FlatRate' as RegieWorkTimeBookingType,
    RegieWithBillingType: 'RegieWithBillingType' as RegieWorkTimeBookingType,
    WarrantyWithBillingType: 'WarrantyWithBillingType' as RegieWorkTimeBookingType
};

